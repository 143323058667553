import * as React from "react"
import { graphql } from "gatsby"
import { Link } from "gatsby";
import Layout from "../../components/layout"
import Seo from "../../components/seo"
import { StaticImage } from "gatsby-plugin-image"

const MembersMC = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata?.title || `Title`

  return (
    <Layout location={location} title={siteTitle}>
      <Seo title="Data Entry：M.C｜メンバー紹介｜About us｜株式会社スタートアッププラス" />

      <div className="block xl:flex justify-between">

        <div id="pageTitle" className="relative xl:fixed w-full xl:w-48 h-auto xl:h-full text-white bg-aboutusPage bg-no-repeat bg-center bg-cover  z-20 ">
          <h1 className="font-Copperplate text-center text-2xl sm:text-3xl py-10">
            About us
          </h1>
          <ul className="w-full text-center pb-5">
            <li className='mb-5'>
              <Link to="/aboutus-data" className=''>
                データで見るSUP
              </Link>
            </li>
            <li className='mb-5'>
              <Link to="/aboutus-environment" className=''>
                働く環境
              </Link>
            </li>
            <li className='mb-5'>
              <Link to="/aboutus-members" className=''>
                メンバー紹介
              </Link>
            </li>
          </ul>
        </div>

        <div id="main" className="static xl:ml-48 w-full">
          <div className=" w-11/12 max-w-[1000px] mx-auto py-10 sm:py-10">

            <section id="memberProfile" className="mb-20">
              <div className="flex flex-wrap justify-center sm:justify-start">

                <div className="flex w-full md:w-1/2 p-3">
                  <div className="w-1/3">
                    <StaticImage src="../../images/m-c.png" className="w-full h-auto max-w-[160px]"/>
                  </div>
                  <div className="w-2/3 px-4">
                    <h3 className=" pl-2 my-3 border-l-4 border-key-sand">
                      管理チーム<br />データ入力
                    </h3>
                    <h2 className="text-xl pl-3 mb-3 font-medium">M.C</h2>
                    <p className="text-xs pl-3">入社：2021年2月<br /></p>
                  </div>
                </div>

                <div className=" w-full md:w-1/2 p-3">
                  <div className="w-full h-full p-3 border border-key-sand rounded-xl">
                    <p className="pb-2 list-none flex items-center border-b border-dashed border-key-sand">
                      <StaticImage src="../../images/FAQicon.png" className=" w-[30px] h-auto mr-3"/>
                      現在の仕事内容は？
                    </p>
                    <p className="p-2">
                    データ入力です。
                    </p>
                  </div>
                </div>

                <div className=" w-full md:w-1/2 p-3">
                  <div className="w-full h-full p-3 border border-key-sand rounded-xl">
                    <p className="pb-2 list-none flex items-center border-b border-dashed border-key-sand">
                      <StaticImage src="../../images/FAQicon.png" className=" w-[30px] h-auto mr-3"/>
                      職場の雰囲気は？
                    </p>
                    <p className="p-2">
                    過度な上下関係がなく意見をしやすかったり、解らないことを質問したり相談したりできる風通しの良い職場だと思います。
                    </p>
                  </div>
                </div>

                <div className=" w-full md:w-1/2 p-3">
                  <div className="w-full h-full p-3 border border-key-sand rounded-xl">
                    <p className="pb-2 list-none flex items-center border-b border-dashed border-key-sand">
                      <StaticImage src="../../images/FAQicon.png" className=" w-[30px] h-auto mr-3"/>
                      スタートアッププラスの良いところは？
                    </p>
                    <p className="p-2">
                    基本自宅勤務なので自分でスケジュールが組めて自分のペースで作業を進められます。ほかには社内行事などもあるので楽しみができます。
                    </p>
                  </div>
                </div>

                <div className=" w-full md:w-1/2 p-3">
                  <div className="w-full h-full p-3 border border-key-sand rounded-xl">
                    <p className="pb-2 list-none flex items-center border-b border-dashed border-key-sand">
                      <StaticImage src="../../images/FAQicon.png" className=" w-[30px] h-auto mr-3"/>
                      会社を選ぶ際にこだわったことは？
                    </p>
                    <p className="p-2">
                    自分でできそうな範囲の仕事内容なのかというのが前提で、職場の場所とこんなご時世なので在宅があるかどうか。<br />
                    あとは、オフィスの綺麗さも私にとって選ぶ際のポイントでした。
                    </p>
                  </div>
                </div>

                <div className=" w-full md:w-1/2 p-3">
                  <div className="w-full h-full p-3 border border-key-sand rounded-xl">
                    <p className="pb-2 list-none flex items-center border-b border-dashed border-key-sand">
                      <StaticImage src="../../images/FAQicon.png" className=" w-[30px] h-auto mr-3"/>
                      入社を決めた要因を教えてください。
                    </p>
                    <p className="p-2">
                    面接官の人がとてもいい人だなと思ったのとオフィスが綺麗。在宅ワークもしっかりあったのでここで働きたいと思いました。
                    </p>
                  </div>
                </div>

                <div className=" w-full md:w-1/2 p-3">
                  <div className="w-full h-full p-3 border border-key-sand rounded-xl">
                    <p className="pb-2 list-none flex items-center border-b border-dashed border-key-sand">
                      <StaticImage src="../../images/FAQicon.png" className=" w-[30px] h-auto mr-3"/>
                      入社して驚いた事はありますか？
                    </p>
                    <p className="p-2">
                    Gパンなどのカジュアルな服装での出社がOK。わざわざオフィスカジュアルを揃える必要もないのでとても楽です！
                    </p>
                  </div>
                </div>

                <div className=" w-full md:w-1/2 p-3">
                  <div className="w-full h-full p-3 border border-key-sand rounded-xl">
                    <p className="pb-2 list-none flex items-center border-b border-dashed border-key-sand">
                      <StaticImage src="../../images/FAQicon.png" className=" w-[30px] h-auto mr-3"/>
                      印象に残っている出来事はありますか？
                    </p>
                    <p className="p-2">
                    入社したばかりの時は緊急事態宣言などもあり、出社が他のチームの方と被ることがなかったので名前と顔を覚えるまでに結構時間がかかったなというのが印象に残っています(･ω･)
                    </p>
                  </div>
                </div>

                <div className=" w-full md:w-1/2 p-3">
                  <div className="w-full h-full p-3 border border-key-sand rounded-xl">
                    <p className="pb-2 list-none flex items-center border-b border-dashed border-key-sand">
                      <StaticImage src="../../images/FAQicon.png" className=" w-[30px] h-auto mr-3"/>
                      仕事とプライベートの両立はできていますか？
                    </p>
                    <p className="p-2">
                    とてもできていますし土日祝が休み＋有給もとれるのでプライベートも充実しています。
                    </p>
                  </div>
                </div>

              </div>
            </section>

            <Link to="/aboutus-members" class=" m-5 flex justify-center border border-neutral-800 px-4 py-3 transition duration-300 ease-in-out hover:bg-key-sand">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-arrow-left-short" viewBox="0 0 16 16">
                <path fill-rule="evenodd" d="M12 8a.5.5 0 0 1-.5.5H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5a.5.5 0 0 1 .5.5z"></path>
              </svg>
              <span className=" ">メンバーの一覧に戻る</span>
            </Link>

          </div>
        </div>
      </div>

    </Layout>

    
  )
}



export default MembersMC

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`

